import { experimentStates } from '@zola-helpers/client/dist/es/experiments';
import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

// LogRocket Keys
export const LOGROCKET_PLANNING_ONBOARD_FLAG = 'logrocket-planning-onboard' as const;
export const LOGROCKET_WEBSITE_ONBOARD = 'logrocket-website-onboard' as const;
export const SUPER_LINK_LOG_ROCKET = 'magic-link-log-rocket' as const; // CE-847 https://newamsterdamlabs.atlassian.net/browse/CE-847
export const LOGROCKET_INVITES_PDP = 'logrocket-invites-pdp' as const; // COMMERCE-585 https://newamsterdamlabs.atlassian.net/browse/COMMERCE-585
export const LOGROCKET_YOUR_WEDDING_V2 = 'logrocket-your-wedding-v2' as const;

// Experiment Keys
export const DSTD_WEDDING_WEBSITE_ENTRYPOINT = 'dstd-wedding-website-entrypoint-ii' as const; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1133
export const HDYHAU_SUBWAY = 'hdyhau-subway' as const;
export const DSTD_NAV_ENTRY = 'dstd-navigation-entry' as const;
export const CHAR_COUNT_INCREASE = 'website-char-count-increase' as const; // https://newamsterdamlabs.atlassian.net/browse/CE-1876
export const RECAPTCHA_REFACTOR = 'recaptcha-refactor' as const; // https://newamsterdamlabs.atlassian.net/browse/CE-2186
export const ONBOARDING_GP_MOBILE_JAN_2023 = 'onboarding-gp-mobile-jan-2023' as const; // https://newamsterdamlabs.atlassian.net/browse/CE-2908
export const ONBOARDING_GP_MOBILE_DROP_STEPS = 'onboard-gp-mobile-drop-extra-steps' as const;
export const PAPER_PLP_SALE_EVENT = 'paper-invite-ty-sale-event' as const;
export const CORE_LOGROCKET_MANAGE_WEBSITE = 'core-logrocket-manage-website' as const; // https://newamsterdamlabs.atlassian.net/browse/CE-3769
export const WW_REGISTRY_REDESIGN = 'ww-to-registry-redesign' as const; // https://newamsterdamlabs.atlassian.net/browse/CE-3818
export const CLP_LP_LINKS_TO_SRP_V2 = 'marketplace-clp-lp-links-to-srp-v2'; // PE-1348
export const NEW_PAPER_TYPE_NAPKINS = 'new-paper-type-napkins'; // https://newamsterdamlabs.atlassian.net/browse/PE-1342
export const NEW_PAPER_TYPE_SIGNAGE = 'new-paper-type-signage'; // https://newamsterdamlabs.atlassian.net/browse/PE-1344
export const NEW_PAPER_TYPE_STICKERS = 'new-paper-type-stickers'; // https://newamsterdamlabs.atlassian.net/browse/PE-1343
export const ENVELOPE_STEP_IMPROVEMENTS = 'envelope-step-improvements'; // https://newamsterdamlabs.atlassian.net/browse/PE-1201

// Seasonal flags
export const HOLIDAY_CARDS = 'holiday-cards' as const;

// Promo flags

const FALLBACK_VARIATIONS = {
  // LogRocket Flags
  [LOGROCKET_PLANNING_ONBOARD_FLAG]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_WEBSITE_ONBOARD]: experimentFlagsHelper.createExperimentFlags(),
  [SUPER_LINK_LOG_ROCKET]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_INVITES_PDP]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_YOUR_WEDDING_V2]: experimentFlagsHelper.createExperimentFlags(),
  // Experiment Flags
  [DSTD_WEDDING_WEBSITE_ENTRYPOINT]: experimentFlagsHelper.createExperimentFlags(),
  [HDYHAU_SUBWAY]: experimentFlagsHelper.createExperimentFlags(),
  [DSTD_NAV_ENTRY]: experimentFlagsHelper.createExperimentFlags(),
  [RECAPTCHA_REFACTOR]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_GP_MOBILE_JAN_2023]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_GP_MOBILE_DROP_STEPS]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_PLP_SALE_EVENT]: experimentFlagsHelper.createExperimentFlags(),
  [CORE_LOGROCKET_MANAGE_WEBSITE]: experimentFlagsHelper.createExperimentFlags(),
  [WW_REGISTRY_REDESIGN]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_CARDS]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_NAPKINS]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_SIGNAGE]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_STICKERS]: experimentFlagsHelper.createExperimentFlags(),
  [CLP_LP_LINKS_TO_SRP_V2]: experimentFlagsHelper.createExperimentFlags(),
};

export type ProductExperimentFlag = keyof typeof FALLBACK_VARIATIONS;

/**
 * Lookup of product experiment flags to their variation. Typically this is
 * going to be used by a page that renders in next where a SSR component needs
 * the experiment variation.  We need to look the experiment flag up on the
 * server (with experimentFlagsSSR) and use the same experiment variation on the
 * client (and trigger experiment viewed)
 */
export type ProductExperimentFlagLookup = {
  [flag in ProductExperimentFlag]?: string;
};

export const experimentFlags = experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);
export default experimentFlags;

export const experimentFlagsSSR = experimentsFlagsHelperSSR.makeExperimentFlags(
  FALLBACK_VARIATIONS
);

const { NOT_IN_TEST, CONTROL } = experimentStates;

/**
 * @function isInExperiment
 *
 * Helper function to check if variant is an experiment
 * Note: this will NOT work for an experiment that is a release ramp (i.e. experiment with only 1 variant)
 *
 * @param {string} variant
 *
 * @returns {boolean}
 */
export const isInExperiment = (variant: string): boolean =>
  variant !== 'TEST-0' && variant !== 'NOT_IN_TEST' && /^TEST-[1-9]$/.test(variant);

/**
 * Is the user in the experiment and are they the control group
 */
export const isControl = (variant: string): boolean => variant === CONTROL;

/**
 * Is the in the experiment and are they _not_ the control group.
 * If you have more than one variant (ie, and A/B/C test) instead
 * of a single variant (classic A/B test) you will need to check
 * the getExperimentVariation directly as you would have values such
 * as NOT_IN_TEST, TEST-0, TEST-1, and TEST-2.
 */
export const isUnderTest = (variant: string): boolean =>
  variant !== CONTROL && variant !== NOT_IN_TEST;
